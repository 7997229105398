import React from 'react'
import './baner.css'
const Baner = () => {
  return (
    <div className='banner'>
      <div className="banner_content">
        <h3>Egyptian Tendering</h3>
        <p>with you any time</p>
      </div>
      <div className="overlay">

      </div>
    </div>
  )
}

export default Baner
